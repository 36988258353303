<template>
  <div class="d-flex flex-column flex-root">
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
    >
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <div class="text-center mb-5 mb-lg-5">
              <h3 class="font-size-h1">Login to your account</h3>

            </div>
            <!--begin::Multi Authenticator Form-->
            <b-form class="kt-form" v-if="multiAuthenticator">
              <div
                  role="alert"
                  class="alert alert-danger"
                  v-if="errors.length > 0"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <b-form-group
                  id="example-input-group-1"
                  label
                  label-for="example-input-1"
                  v-if="!verificationEmailBtn"
              >
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    id="example-input-1"
                    name="example-input-1"
                    @keypress.enter.native.prevent
                    placeholder="MFA Verification Code"
                    v-model="authenticator.mfa.verification_code"
                    aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                >Verification code
                </b-form-invalid-feedback
                >
              </b-form-group>

              <b-form-group
                  label
                  label-for="email-verification-code"
                  v-if="verificationEmailBtn"
              >
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    name="email-verification-code"
                    @keypress.enter.native.prevent
                    placeholder="Email Verification Code"
                    v-model="authenticator.email.verification_code"
                    aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                >Email verification code
                </b-form-invalid-feedback
                >
              </b-form-group>

              <!--begin::Divider-->
              <div class="kt-login__divider">
                <div class="kt-divider">
                  <span></span>
                  <span
                      class="kt-link kt-login__signup-link"
                      @click="verificationEmailBtn = !verificationEmailBtn"
                  >Try alternative</span
                  >
                  <span></span>
                </div>
              </div>

              <div class="kt-login__actions">
                <span
                    class="kt-login__signup-label"
                    v-if="!verificationEmailBtn"
                ></span>
                <b-button
                    v-if="verificationEmailBtn"
                    type="button"
                    @click="requestEmailVerificationCode"
                    class="btn btn-warning btn-elevate"
                    :disabled="emailVerificationMailRequested"
                >
                  {{
                    emailVerificationMailRequested
                        ? "You can request again after 1 minute"
                        : "Request email verification"
                  }}
                </b-button>

                <b-button
                    type="button"
                    @click="verifyMultiAuthCode"
                    id="verify_btn"
                    ref="kt_login_signin_submit"
                    class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                >Verify
                </b-button
                >
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Multi Authenticator Form-->

            <!--begin::MFA Verification Form-->
            <b-form
                class="kt-form"
                @submit.stop.prevent="verifyMfaAuthenticator"
                v-if="mfaAuthenticator"
            >
              <div
                  role="alert"
                  class="alert alert-danger"
                  v-if="errors.length > 0"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <b-form-group
                  id="example-input-group-1"
                  label
                  label-for="example-input-1"
              >
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    id="example-input-1"
                    name="example-input-1"
                    placeholder="Verification Code"
                    v-model="authenticator.mfa.verification_code"
                    aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                >Verification code
                </b-form-invalid-feedback
                >
              </b-form-group>

              <div class="kt-login__actions">
                <span class="kt-login__signup-label"></span>
                <b-button
                    type="submit"
                    id="verify_btn"
                    ref="kt_login_signin_submit"
                    class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                >Verify
                </b-button
                >
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::MFA Verification Form-->

            <!--begin::Email Verification Form-->
            <b-form class="kt-form" v-if="emailAuthenticator">
              <div
                  role="alert"
                  class="alert alert-danger"
                  v-if="errors.length > 0"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <b-form-group
                  id="example-input-group-1"
              >
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    id="example-input-1"
                    name="example-input-1"
                    placeholder="Email Verification Code"
                    v-model="authenticator.email.verification_code"
                    aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback"
                >Verification code
                </b-form-invalid-feedback
                >
              </b-form-group>

              <div class="kt-login__actions">
                <span class="kt-login__signup-label"></span>
                <b-button
                    type="button"
                    @click="verifyEmailAuthenticator"
                    id="verify_btn"
                    ref="kt_login_signin_submit"
                    class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                >Verify
                </b-button
                >
              </div>
              <!--end::Action-->
            </b-form>
            <!--end::Email  Verification Form-->

            <b-form
                class="kt-form"
                @submit.stop.prevent="onSubmit"
                v-if="
                !mfaAuthenticator &&
                !emailAuthenticator &&
                !multiAuthenticator
              "
            >
              <div
                  role="alert"
                  class="alert alert-danger"
                  v-if="errors.length > 0"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <b-form-group
                  id="example-input-group-1"
                  label-for="example-input-1"
              >
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    id="example-input-1"
                    name="example-input-1"
                    placeholder="Enter your email"
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback">
                  Email is required and a valid email address.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  id="example-input-group-2"
                  label-for="example-input-2"
              >
                <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    type="password"
                    id="example-input-2"
                    name="example-input-2"
                    placeholder="Enter your password"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="input-2-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-2-live-feedback"
                >Password is required.
                </b-form-invalid-feedback
                >
              </b-form-group>

              <router-link
                  v-if="accountNotVerified"
                  class="btn btn-primary text-white mt-3 col-md-12"
                  :to="{ name: 'verification' }"
              >Verify your account
              </router-link
              >
              <div
                  class="form-group d-flex flex-wrap justify-content-between align-items-center"
              >
                <button
                    type="submit"
                    id="login_btn"
                    ref="kt_login_signin_submit"
                    class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
                >
                  {{ $t("AUTH.LOGIN.BUTTON") }}
                </button>
              </div>
            </b-form>

            <vue-recaptcha
                ref="invisibleRecaptcha"
                :sitekey="sitekey"
                @verify="onCaptchaVerified"
                :loadRecaptchaScript="true"
                size="invisible"
            ></vue-recaptcha>
          </div>
          <!--end::Signin-->
        </div>
      </div>


      <!--begin::Content header-->
      <div
          class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
      >
      <span class="font-weight-bold font-size-3 text-dark-60">

      </span>

      </div>
      <!--end::Content header-->
    </div>


    <vue-snotify></vue-snotify>
  </div>

</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {mapState} from "vuex";
import {LOGIN} from "@/core/services/store/auth.module";
import VueRecaptcha from "vue-recaptcha";
import {validationMixin} from "vuelidate";
import {email, minLength, required} from "vuelidate/lib/validators";
import userService from "@/core/services/user/UserService";


const UserService = new userService();

export default {
  components: {VueRecaptcha},
  mixins: [validationMixin],
  name: "login-view",
  data() {
    return {
      errors: [],
      accountNotVerified: false,
      mfaAuthenticator: false,
      emailAuthenticator: false,
      multiAuthenticator: false,
      verificationEmailBtn: false,
      emailVerificationMailRequested: false,
      site_main_url: process.env.VUE_APP_SERVER_URL,
      sitekey:  process.env.VUE_APP_CAPTCHA_SITE_KEY,
      setting: null,
      authenticator: {
        mfa: {
          verification_code: "",
        },
        email: {
          verification_code: "",
        },
      },
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {

    redirectIfLoggedIn() {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push({name: "dashboard"});
      }
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    activateSubmitSpinner(btnId) {
      const submitButton = document.getElementById(btnId);
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    deactivateSubmitSpinner(btnId, seconds) {
      const submitButton = document.getElementById(btnId);
      setTimeout(() => {
        submitButton.classList.remove("kt-spinner", "spinner", "spinner-light", "spinner-right");
      }, seconds * 1000);
    },

    setLogin(data) {
      this.$store
          .dispatch(LOGIN, data)
          // go to which page after successfully login
          .then(() => {
            this.$router.push({name: "dashboard"});
          });
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
    },

    onCaptchaVerified: function () {
      if (!this.$v.form.$anyError) {
        this.activateSubmitSpinner("login_btn");
        // set spinner to submit button
        UserService.checkVerificationEnabled(this.form)
            .then((res) => {
              this.errors = [];
              this.accountNotVerified = false;
              this.deactivateSubmitSpinner("login_btn", 1);
              if (res.data.status == "NOT_FOUND") {
                this.$snotify.error(res.data.errors);
                this.deactivateSubmitSpinner("login_btn", 1);
              } else if (res.data.status == "NOT_VERIFIED") {
                this.$snotify.error(res.data.errors);
                this.accountNotVerified = true;
                this.deactivateSubmitSpinner("login_btn", 1);
              } else if (
                  res.data.data.is_mfa_enabled &&
                  res.data.data.is_email_authentication_enabled
              ) {
                this.multiAuthenticator = true;
              } else if (
                  res.data.data.is_mfa_enabled
              ) {
                this.mfaAuthenticator = true;
              } else if (res.data.data.is_email_authentication_enabled) {
                this.emailAuthenticator = true;
              } else {
                this.setLogin(this.form);
              }
            })
            .catch(() => {
            });
      }
    },

    verifyMultiAuthCode() {
      if (this.verificationEmailBtn) this.verifyEmailAuthenticator();
      else this.verifyMfaAuthenticator();
    },
    verifyMfaAuthenticator() {
      if (this.authenticator.mfa.verification_code == "") {
        this.errors = [];
        this.$snotify.error("Please enter a verification code.");
      } else {
        this.errors = [];
        let data = this.form;
        data.verification_code = this.authenticator.mfa.verification_code;
        UserService.verifyMfaVerificationCode(data)
            .then((res) => {
              if(res.data.status=='OK')
              {
                this.errors = [];
                this.authenticator.mfa.verification_code = "";
                this.deactivateSubmitSpinner("verify_btn", 1);
                this.mfaAuthenticator = false;
                this.setLogin(this.form);
              }
              else
              {
                this.$snotify.error(res.data.errors);
                this.deactivateSubmitSpinner("verify_btn", 1);
              }

            })
            .catch(({response}) => {
              this.$snotify.error(response.data.errors);
              this.deactivateSubmitSpinner("verify_btn", 1);
            });
      }

      setTimeout(() => {
        this.errors = [];
      }, 2000);
    },

    verifyEmailAuthenticator() {
      if (this.authenticator.email.verification_code == "") {
        this.errors = [];
        this.$snotify.error("Please enter a verification code.");
      } else {
        this.errors = [];
        let data = this.form;
        data.verification_code = this.authenticator.email.verification_code;
        UserService.verifyEmailVerificationCode(data)
            .then((res) => {
              if(res.data.status=='OK') {
                this.errors = [];
                this.authenticator.email.verification_code = "";
                this.setLogin(this.form);
              }
              else
              {
                this.$snotify.error(res.data.errors);
                this.deactivateSubmitSpinner("verify_btn", 1);
              }
            })
            .catch(({response}) => {
              this.errors = response.data.errors;
              this.deactivateSubmitSpinner("verify_btn", 1);
            });
      }

      setTimeout(() => {
        this.errors = [];
      }, 2000);
    },

    requestEmailVerificationCode() {
      let data = this.form;
      UserService.requesEmailVerificationCode(data)
          .then((res) => {
            this.errors = [];
            this.emailVerificationMailRequested = true;
          })
          .catch(({response}) => {
            this.emailVerificationMailRequested = false;
            this.errors = response.data.errors;
          });
      setTimeout(() => {
        this.emailVerificationMailRequested = false;
      }, 60000);
    },
  },
  computed: {
    ...mapState({}),

    backgroundImage() {
      return (
          process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  },
};
</script>
